define("discourse/plugins/discourse-category-lockdown/discourse/templates/connectors/category-custom-security/lockdown-settings", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <h3>{{i18n "lockdown.category_setting_heading"}}</h3>
  <section class="field">
    <label class="checkbox-label">
      <Input
        @type="checkbox"
        @checked={{@outletArgs.category.custom_fields.lockdown_enabled}}
      />
      {{i18n "lockdown.lockdown_enabled"}}
    </label>
  </section>
  {{#if @outletArgs.category.custom_fields.lockdown_enabled}}
    <section class="field">
      <label>
        {{i18n "lockdown.redirect_url"}}
      </label>
      <Input
        @type="text"
        @value={{@outletArgs.category.custom_fields.redirect_url}}
      />
    </section>
    <section class="field">
      <label>
        {{i18n "lockdown.lockdown_allowed_groups"}}:
      </label>
      <GroupChooser
        @content={{this.availableGroups}}
        @valueProperty={{null}}
        @nameProperty={{null}}
        @value={{this.selectedGroups}}
        @onChange={{this.onChangeGroups}}
      />
    </section>
  {{/if}}
  */
  {
    "id": "aN1GqO5C",
    "block": "[[[10,\"h3\"],[12],[1,[28,[35,1],[\"lockdown.category_setting_heading\"],null]],[13],[1,\"\\n\"],[10,\"section\"],[14,0,\"field\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"checkbox-label\"],[12],[1,\"\\n    \"],[8,[39,4],null,[[\"@type\",\"@checked\"],[\"checkbox\",[30,1,[\"category\",\"custom_fields\",\"lockdown_enabled\"]]]],null],[1,\"\\n    \"],[1,[28,[35,1],[\"lockdown.lockdown_enabled\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[30,1,[\"category\",\"custom_fields\",\"lockdown_enabled\"]],[[[1,\"  \"],[10,\"section\"],[14,0,\"field\"],[12],[1,\"\\n    \"],[10,\"label\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"lockdown.redirect_url\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[39,4],null,[[\"@type\",\"@value\"],[\"text\",[30,1,[\"category\",\"custom_fields\",\"redirect_url\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"section\"],[14,0,\"field\"],[12],[1,\"\\n    \"],[10,\"label\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"lockdown.lockdown_allowed_groups\"],null]],[1,\":\\n    \"],[13],[1,\"\\n    \"],[8,[39,6],null,[[\"@content\",\"@valueProperty\",\"@nameProperty\",\"@value\",\"@onChange\"],[[30,0,[\"availableGroups\"]],null,null,[30,0,[\"selectedGroups\"]],[30,0,[\"onChangeGroups\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@outletArgs\"],false,[\"h3\",\"i18n\",\"section\",\"label\",\"input\",\"if\",\"group-chooser\"]]",
    "moduleName": "discourse/plugins/discourse-category-lockdown/discourse/templates/connectors/category-custom-security/lockdown-settings.hbs",
    "isStrictMode": false
  });
});