define("discourse/plugins/discourse-category-lockdown/discourse/connectors/topic-link__before/category-lockdown-icon", ["exports", "@glimmer/component", "@ember/service", "discourse-common/helpers/i18n", "discourse-common/helpers/d-icon", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _i18n, _dIcon, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CategoryLockdownIcon extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{~#if this.siteSettings.category_lockdown_enabled~}}
          {{~#if @outletArgs.topic.is_locked_down~}}
            <span title={{i18n "lockdown.topic_is_locked_down"}}>
              {{icon
                this.siteSettings.category_lockdown_list_icon
              }}
            </span>
          {{~/if~}}
        {{~/if~}}
       
    */
    {
      "id": "Xqweqi22",
      "block": "[[[41,[30,0,[\"siteSettings\",\"category_lockdown_enabled\"]],[[[41,[30,1,[\"topic\",\"is_locked_down\"]],[[[10,1],[15,\"title\",[28,[32,0],[\"lockdown.topic_is_locked_down\"],null]],[12],[1,\"\\n          \"],[1,[28,[32,1],[[30,0,[\"siteSettings\",\"category_lockdown_list_icon\"]]],null]],[1,\"\\n        \"],[13]],[]],null]],[]],null]],[\"@outletArgs\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-category-lockdown/discourse/connectors/topic-link__before/category-lockdown-icon.js",
      "scope": () => [_i18n.default, _dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CategoryLockdownIcon;
});